import { Check, Pencil, Plus, Trash, X } from "phosphor-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import EmptyState from "../components/EmptyState";
import Input from "../components/Input";
import PaletteItem from "../components/PaletteItem";
import { useNavigation } from "../navigation";
import { usePalette } from "../palette";
import { Palette } from "../types";
import { isTablet } from "../utils/media";
import styles from "./SidebarPalette.module.css";

type EditMode = "edit" | "delete" | "create" | undefined;

const PaletteEditForm = ({
  initialValue,
  onSubmit,
  onCancel,
}: {
  initialValue: string;
  onSubmit: (name: string) => void;
  onCancel: () => void;
}) => {
  const [name, setName] = useState(initialValue);

  return (
    <form
      onSubmit={(evt) => {
        evt.preventDefault();
        onSubmit(name);
      }}
      className={styles.paletteSelector}
    >
      <Input value={name} autoFocus onChange={(e) => setName(e.target.value)} />
      <Button icon type="submit">
        <Check className="color-success" />
      </Button>
      <Button icon onClick={onCancel} type="button">
        <X className="color-danger" />
      </Button>
    </form>
  );
};

const PaletteSelector = ({
  palettes = [],
  value,
  onChange,
  onEditClick,
}: {
  palettes: Palette[] | undefined;
  value: number;
  onChange: (paletteId: number) => void;
  onEditClick: (mode: EditMode) => void;
}) => {
  return (
    <div className={styles.paletteSelector}>
      <select
        value={value}
        onChange={(evt) => onChange(Number(evt.target.value))}
      >
        {palettes.map((p) => (
          <option key={p.id} value={p.id}>
            {p.name}
          </option>
        ))}
      </select>
      <Button icon onClick={() => onEditClick("create")}>
        <Plus />
      </Button>
      <Button icon onClick={() => onEditClick("edit")}>
        <Pencil />
      </Button>
      <Button
        icon
        onClick={() => onEditClick("delete")}
        disabled={palettes.length === 1}
      >
        <Trash />
      </Button>
    </div>
  );
};

const SidebarPalette = () => {
  const {
    paints,
    palette,
    palettes,
    deleteFromPalette,
    removePalette,
    createPalette,
    updatePalette,
    setActivePalette,
  } = usePalette();
  const { toggleSidebar } = useNavigation();
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState<EditMode | undefined>();

  const handleColorClick = (id: string) => () => {
    if (isTablet()) {
      toggleSidebar();
    }
    navigate(`/paints/${id}`);
  };

  const handleCreatePalette = async (name: string) => {
    const paletteId = await createPalette(name);
    setActivePalette(paletteId);
    setEditMode(undefined);
  };

  const handleRemovePalette = () => {
    if (!palette?.id) {
      return;
    }
    removePalette(palette.id);
    setActivePalette(undefined);
    setEditMode(undefined);
  };

  const handleEditPaletteSubmit = (name: string) => {
    if (!palette?.id) {
      return;
    }
    updatePalette(palette.id, { name });
    setEditMode(undefined);
  };

  const renderSelector = () => {
    switch (editMode) {
      case "create":
        return (
          <PaletteEditForm
            initialValue="New palette"
            onSubmit={handleCreatePalette}
            onCancel={() => setEditMode(undefined)}
          />
        );
      case "edit":
        return (
          <PaletteEditForm
            initialValue={palette?.name || ""}
            onSubmit={handleEditPaletteSubmit}
            onCancel={() => setEditMode(undefined)}
          />
        );
      case "delete":
        return (
          <div className={styles.paletteSelector}>
            <div>Delete palette?</div>
            <Button onClick={handleRemovePalette} icon>
              <Check className="color-success" />
            </Button>
            <Button onClick={() => setEditMode(undefined)} icon>
              <X className="color-danger" />
            </Button>
          </div>
        );
      default:
        return (
          <PaletteSelector
            palettes={palettes}
            value={palette?.id || 0}
            onChange={setActivePalette}
            onEditClick={setEditMode}
          />
        );
    }
  };

  return (
    <div className={styles.container}>
      {renderSelector()}
      <div>
        <h4 className={styles.title}>Palette</h4>
        {paints && paints.length > 0 ? (
          <>
            {paints.map((p) => (
              <PaletteItem
                key={p.id}
                paint={p}
                onColorClick={handleColorClick(p.id)}
                onDeleteClick={() => deleteFromPalette(p.id)}
              />
            ))}
          </>
        ) : (
          <EmptyState message="Your palette is empty." />
        )}
      </div>
    </div>
  );
};

export default SidebarPalette;
