import Dexie, { Table } from "dexie";
import { Meta, Paint, Palette, PalettePaint } from "./types";

export class ModelPaintStudioDexie extends Dexie {
  palette!: Table<Palette, number>;
  paints!: Table<Paint, string>;
  palettePaints!: Table<PalettePaint>;
  meta!: Table<Meta>;

  constructor() {
    super("ModelPaintStudioDB");
    this.version(1).stores({
      palette: "++id, paintIds",
      paints: "id, name, brand, line, hex",
    });

    this.version(2).stores({
      meta: "id, version",
    });

    this.version(3).stores({
      paints: "id, name, brand, line, hex, code",
    });

    this.version(4).stores({
      palette: "++id, name",
      palettePaints: "++id, paletteId, paintId, order",
    });

    this.on("populate", () => {
      this.palette.add({
        name: "Default",
      });
    });
  }
}

export const db = new ModelPaintStudioDexie();
