import { ColorSpace, contrast, sRGB } from "colorjs.io/fn";
import { Paint, PaintDetails } from "../types";

ColorSpace.register(sRGB);

export const paintToSelectOption = (paint: Paint) => ({
  label: paint.line ? `${paint.line} - ${paint.name}` : `${paint.name}`,
  value: paint.id,
  hex: paint.hex,
});

export const paintDetailsToPaint = (paint: PaintDetails | Paint) => ({
  id: paint.id,
  code: paint.code,
  name: paint.name,
  brand: paint.brand,
  hex: paint.hex,
  line: paint.line,
  type: paint.type,
});

export const conjoin = (separator?: string, ...args: (string | undefined)[]) =>
  args.filter(Boolean).join(separator);

export const isDarkColor = (hex: string) => {
  return contrast("#fff", hex, "APCA") < 50;
};
