import classNames from "classnames";
import styles from "./Input.module.css";
import { forwardRef } from "react";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {}

const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
  return (
    <input
      ref={ref}
      className={classNames(props.className, styles.input)}
      {...props}
    />
  );
});

export default Input;
