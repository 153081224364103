import classNames from "classnames";
import {
  KeyboardEventHandler,
  MouseEventHandler,
  PropsWithChildren,
} from "react";
import styles from "./Button.module.css";

type ButtonVariant = "primary" | "translucent";

interface Props extends PropsWithChildren {
  className?: string;
  disabled?: boolean;
  title?: string;
  icon?: boolean;
  fullWidth?: boolean;
  variant?: ButtonVariant;
  href?: string;
  target?: string;
  rel?: string;
  type?: "button" | "submit" | "reset";
  onKeyDown?: KeyboardEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
}

const getVariantClass = (variant?: ButtonVariant) => {
  switch (variant) {
    case "translucent":
      return styles.translucent;
    default:
      return undefined;
  }
};

const Button = ({
  className,
  fullWidth,
  title,
  icon,
  children,
  disabled,
  variant,
  href,
  target,
  type,
  onKeyDown,
  onClick,
}: Props) => {
  const Component = href ? "a" : "button";

  return (
    <Component
      title={title}
      aria-label={title}
      disabled={disabled}
      className={classNames(
        className,
        styles.button,
        icon && styles.iconButton,
        fullWidth && styles.fullWidth,
        getVariantClass(variant)
      )}
      onKeyDown={onKeyDown}
      onClick={onClick}
      href={href}
      target={target}
      type={type}
    >
      {children}
    </Component>
  );
};

export default Button;
