import { Outlet, ScrollRestoration } from "react-router-dom";
import Navbar from "../components/Navbar";
import styles from "./Root.module.css";

const Root = () => {
  return (
    <>
      <Navbar />
      <div className={styles.main}>
        <Outlet />
      </div>
      <ScrollRestoration />
    </>
  );
};

export default Root;
