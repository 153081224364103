import { useLiveQuery } from "dexie-react-hooks";
import keyBy from "lodash/keyBy";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { db } from "./db";
import { Paint, PaintDetails, Palette } from "./types";
import { paintDetailsToPaint } from "./utils/utils";

interface PaletteContextProps {
  palettes?: Palette[];
  palette?: Palette | null;
  paints?: Paint[] | null;
  addToPalette(paint: Paint): void;
  createPalette(name: string): Promise<number>;
  removePalette(paletteId: number): void;
  updatePalette(paletteId: number, palette: Palette): void;
  setActivePalette(paletteId?: number): void;
  deleteFromPalette(paintId: string): void;
  paletteContains(paintId: string): boolean;
}

export const PaletteContext = React.createContext<PaletteContextProps>({
  palette: null,
  paints: null,
  addToPalette: () => {},
  createPalette: () => Promise.resolve(0),
  updatePalette: () => {},
  removePalette: () => {},
  setActivePalette: () => {},
  deleteFromPalette: () => {},
  paletteContains: () => false,
});

export const PaletteProvider = ({ children }: PropsWithChildren) => {
  const [activePalette, setActivePalette] = useState<number>();

  useEffect(() => {
    // if no palette is selected, select the first one
    if (!activePalette) {
      db.palette
        .toCollection()
        .last()
        .then((palette) => {
          if (palette) {
            setActivePalette(palette.id);
          }
        });
    }
  }, [activePalette]);

  const palettesRes = useLiveQuery(async () => {
    const palettes = await db.palette.toArray();
    return palettes;
  });

  const paletteRes = useLiveQuery(async () => {
    if (!activePalette) {
      return null;
    }
    const palette = await db.palette.get(activePalette);
    return palette;
  }, [activePalette]);

  const paintsRes = useLiveQuery(async () => {
    if (!paletteRes || !paletteRes.id) {
      return [];
    }

    const paintRelations = await db.palettePaints
      .where("paletteId")
      .equals(paletteRes.id)
      .sortBy("order");

    const paints = await db.paints
      .where("id")
      .anyOf(paintRelations.map((relation) => relation.paintId))
      .toArray();

    const paintsById = keyBy(paints, "id");

    return paintRelations.map((relation) => paintsById[relation.paintId]);
  }, [paletteRes]);

  const createPalette = async (name: string) => {
    const palette = await db.palette.add({
      name,
    });
    return palette;
  };

  const removePalette = async (paletteId: number) => {
    return db.palette.delete(paletteId);
  };

  const addToPalette = async (paint: Paint | PaintDetails) => {
    if (!paletteRes || !paletteRes.id) {
      throw new Error("No palette selected.");
    }

    db.paints.put(paintDetailsToPaint(paint));

    const maxOrder = await db.palettePaints
      .where("paletteId")
      .equals(paletteRes.id)
      .reverse()
      .sortBy("order");

    await db.palettePaints.add({
      paletteId: paletteRes.id,
      paintId: paint.id,
      order: maxOrder.length !== 0 ? maxOrder[0].order + 1 : 0,
    });
  };

  const updatePalette = async (paletteId: number, palette: Palette) => {
    return db.palette.update(paletteId, palette);
  };

  const deleteFromPalette = async (paintId: string) => {
    if (!paletteRes || !paletteRes.id) {
      throw new Error("No palette selected.");
    }
    return db.palettePaints
      .where("paletteId")
      .equals(paletteRes.id)
      .and((paint) => paint.paintId === paintId)
      .delete();
  };

  const paletteContains = (paintId: string) => {
    if (!paletteRes || !paintsRes) {
      return false;
    }
    return paintsRes.some((p) => p.id === paintId);
  };

  return (
    <PaletteContext.Provider
      value={{
        palettes: palettesRes,
        palette: paletteRes,
        paints: paintsRes,
        addToPalette,
        createPalette,
        removePalette,
        updatePalette,
        setActivePalette,
        paletteContains,
        deleteFromPalette,
      }}
    >
      {children}
    </PaletteContext.Provider>
  );
};

export const usePalette = () => React.useContext(PaletteContext);
