import React, { MouseEventHandler } from "react";

interface Props {
  color: string;
  size?: string;
  onClick?: MouseEventHandler;
}

const ColorBox = ({ color, onClick, size = "60px" }: Props) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        background: color,
        cursor: onClick ? "pointer" : "inherit",
        flexShrink: "0",
        borderRadius: "var(--border-radius)",
      }}
      onClick={onClick}
    />
  );
};

export default ColorBox;
