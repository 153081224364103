import classNames from "classnames";
import { Check, Plus, ShoppingCart } from "phosphor-react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import Button from "../components/Button";
import ColorBox from "../components/ColorBox";
import EmptyState from "../components/EmptyState";
import PageLayout, {
  PageLayoutContent,
} from "../components/PageLayout/PageLayout";
import PaintTable from "../components/PaintTable";
import Spinner from "../components/Spinner";
import { PaletteContext } from "../palette";
import { PaintDetails, PaintMatch } from "../types";
import { getAmazonAffiliateLink } from "../utils/amazon";
import { fetcher } from "../utils/api";
import { conjoin } from "../utils/utils";
import ActionPaintCard from "./ActionPaintCard";
import styles from "./PaintDetails.module.css";

enum HarmonyType {
  Complement = "Complement",
  SplitComplement = "Split Complement",
  Analogous = "Analogous",
  Triadic = "Triadic",
  Tetradic = "Tetradic",
}

const getPaintName = (paint: PaintDetails) =>
  paint.brand + " " + paint.line + " " + paint.name + " " + paint.code;

function PaintDetailsView() {
  const [harmonyType, setHarmonyType] = useState(HarmonyType.Tetradic);
  const [brandFilter, setBrandFilter] = useState("");
  const { paintId } = useParams();
  const { paletteContains, addToPalette, deleteFromPalette } =
    useContext(PaletteContext);

  const {
    data: paintDetails,
    isLoading,
    error,
  } = useSWR<PaintDetails>(
    "/api/paints/" + encodeURIComponent(paintId ?? ""),
    fetcher
  );

  const alternativesOptions = useMemo(() => {
    if (!paintDetails) {
      return [];
    }

    const values = new Set<string>();

    for (const alt of paintDetails.alternatives) {
      values.add(alt.paint.brand);
    }

    const options = Array.from(values).map((option) => ({
      label: option,
      value: option,
    }));

    return [
      {
        label: "All brands",
        value: "",
      },
      ...options,
    ];
  }, [paintDetails]);

  useEffect(() => {
    if (paintDetails?.name) {
      document.title = paintDetails.name + " - Paints";
    }
  }, [paintDetails?.name]);

  const renderSection = (data: PaintMatch[]) => (
    <PaintTable>
      {data.map((match, i) => (
        <div key={i}>
          <ActionPaintCard data={match.paint} />
        </div>
      ))}
    </PaintTable>
  );

  if (error) {
    return (
      <PageLayout>
        <PageLayoutContent>
          <EmptyState message="Oops! Something went wrong." />
        </PageLayoutContent>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <PageLayoutContent>
        <div className={classNames("container", "container--sm", styles.root)}>
          {isLoading ? (
            <Spinner />
          ) : (
            <div>
              {paintDetails && (
                <>
                  <div className={styles.header}>
                    <div className={styles.info}>
                      <ColorBox color={paintDetails.hex} size="80px" />
                      <div className={styles.paintInfo}>
                        <span>{paintDetails.code || "-"}</span>
                        <h1 className={styles.title}>{paintDetails.name}</h1>
                        <p className={styles.titleMeta}>
                          {conjoin(
                            " - ",
                            paintDetails.brand,
                            paintDetails.line
                          )}
                        </p>
                      </div>
                    </div>
                    <div className={styles.actions}>
                      {paletteContains(paintDetails.id) ? (
                        <Button
                          onClick={() => deleteFromPalette(paintDetails.id)}
                        >
                          <Check className="color-success" size="1.1rem" />
                          In palette
                        </Button>
                      ) : (
                        <Button onClick={() => addToPalette(paintDetails)}>
                          <Plus size="1.1rem" />
                          Add to palette
                        </Button>
                      )}
                      <Button
                        href={getAmazonAffiliateLink(
                          getPaintName(paintDetails)
                        )}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <ShoppingCart size="1.1rem" />
                        Buy from Amazon
                      </Button>
                    </div>
                  </div>
                  <section className={styles.section}>
                    <h2>Color harmonies</h2>
                    <div className={styles.sectionFilter}>
                      <select
                        value={harmonyType}
                        onChange={(e) =>
                          setHarmonyType(e.target.value as HarmonyType)
                        }
                      >
                        {Object.values(HarmonyType).map((harmony) => (
                          <option value={harmony} key={harmony}>
                            {harmony}
                          </option>
                        ))}
                      </select>
                    </div>
                    {renderSection(
                      paintDetails.harmonies.find((h) => h.name === harmonyType)
                        ?.paints || []
                    )}
                  </section>
                  {paintDetails.alternatives.length > 0 && (
                    <section className={styles.section}>
                      <h2>Alternatives</h2>
                      <div className={styles.sectionFilter}>
                        <select
                          onChange={(e) => {
                            setBrandFilter(e.target.value);
                          }}
                        >
                          {alternativesOptions.map((option) => (
                            <option value={option.value} key={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      {renderSection(
                        paintDetails.alternatives
                          .filter(
                            (alt) =>
                              alt.paint.brand === brandFilter || !brandFilter
                          )
                          .slice(0, 4)
                      )}
                    </section>
                  )}
                  {paintDetails.tints.length > 0 && (
                    <section className={styles.section}>
                      <h2>Tints</h2>
                      {renderSection(paintDetails.tints)}
                    </section>
                  )}
                  {paintDetails.shades.length > 0 && (
                    <section className={styles.section}>
                      <h2>Shades</h2>
                      {renderSection(paintDetails.shades)}
                    </section>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </PageLayoutContent>
    </PageLayout>
  );
}

export default PaintDetailsView;
