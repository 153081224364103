import classNames from "classnames";
import { Compass, Palette } from "phosphor-react";
import { Link } from "react-router-dom";
import { useNavigation } from "../../navigation";
import { usePalette } from "../../palette";
import { isMobile } from "../../utils/media";
import ColorBox from "../ColorBox/ColorBox";
import styles from "./Navbar.module.css";

export function ProductItem({ hit, components }: any) {
  return (
    <a href={hit.url} className="aa-ItemLink">
      <div className="aa-ItemContent">
        <ColorBox color={hit.hex} size="45px" />
        <div className="aa-ItemTitle">
          <components.Highlight hit={hit} attribute="name" />
          <br />
          <components.Highlight hit={hit} attribute="brand" /> -{" "}
          <components.Highlight hit={hit} attribute="line" />
        </div>
      </div>
    </a>
  );
}

const Navbar = () => {
  const { toggleSidebar, sidebarOpen } = useNavigation();
  const { paints } = usePalette();

  const handleLinkClick = () => {
    if (isMobile() && sidebarOpen) {
      toggleSidebar();
    }
  };

  return (
    <div className={classNames(styles.navbar)}>
      <div className={styles.topnav}>
        <ul className={styles.links}>
          <li>
            <Link className={styles.button} onClick={handleLinkClick} to="/">
              <Compass size={"1.5rem"} /> Browse
            </Link>
          </li>
          <li>
            <button
              title={sidebarOpen ? "Close sidebar" : "Open sidebar"}
              aria-label={sidebarOpen ? "Close sidebar" : "Open sidebar"}
              className={classNames(styles.iconButton)}
              onClick={() => toggleSidebar()}
            >
              {paints && paints.length > 0 && (
                <span className={styles.notificationBadge}>
                  {paints.length}
                </span>
              )}
              <Palette size={"1.5rem"} />
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
