import {
  Configure,
  InstantSearch,
  RefinementList,
  SearchBox,
  useInstantSearch,
} from "react-instantsearch";
import ColorRefinementList from "../components/ColorRefinementList";
import EmptyState from "../components/EmptyState";
import { InfiniteHits } from "../components/InfiniteHits/InfiniteHits";
import PageLayout, {
  PageLayoutContent,
  PageLayoutSidepane,
} from "../components/PageLayout/PageLayout";
import { searchClient } from "../searchClient";
import { Paint } from "../types";
import ActionPaintCard from "./ActionPaintCard";
import styles from "./Home.module.css";
import { useEffect } from "react";

const PAINT_INDEX_NAME = process.env.REACT_APP_ALGOLIA_PAINT_INDEX;

export function Panel({
  children,
  header,
  footer,
}: {
  children: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}) {
  return (
    <div className="ais-Panel">
      {header && <div className="ais-Panel-header">{header}</div>}
      <div className="ais-Panel-body">{children}</div>
      {footer && <div className="ais-Panel-footer">{footer}</div>}
    </div>
  );
}

function NoResultsBoundary({ children, fallback }: any) {
  const { results } = useInstantSearch();

  // The `__isArtificial` flag makes sure not to display the No Results message
  // when no hits have been returned.
  if (!results.__isArtificial && results.nbHits === 0) {
    return (
      <>
        {fallback}
        <div hidden>{children}</div>
      </>
    );
  }

  return children;
}

const HitComponent = ({ hit }: { hit: Paint }) => (
  <ActionPaintCard data={hit} />
);

function Home() {
  useEffect(() => {
    document.title = "Browse paints";
  });

  return (
    <PageLayout>
      <InstantSearch searchClient={searchClient} indexName={PAINT_INDEX_NAME}>
        <Configure hitsPerPage={40} />
        <PageLayoutSidepane>
          <div className={styles.filters}>
            <Panel header="Search">
              <SearchBox placeholder="Search paints..." />
            </Panel>
            <Panel header="Brand">
              <RefinementList attribute="brand" showMore={true} />
            </Panel>
            <Panel header="Color">
              <ColorRefinementList attribute="color" limit={50} />
            </Panel>
          </div>
        </PageLayoutSidepane>
        <PageLayoutContent>
          <NoResultsBoundary fallback={<EmptyState message="No results" />}>
            <InfiniteHits hitComponent={HitComponent} className={styles.root} />
          </NoResultsBoundary>
        </PageLayoutContent>
      </InstantSearch>
    </PageLayout>
  );
}

export default Home;
