import { Info } from "phosphor-react";
import styles from "./EmptyState.module.css";

interface Props {
  message: string;
}

const EmptyState = ({ message }: Props) => {
  return (
    <div className={styles.emptyState}>
      <Info size={32} />
      <p>{message}</p>
    </div>
  );
};

export default EmptyState;
