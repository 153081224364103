import classNames from "classnames";
import { Check, Plus } from "phosphor-react";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Paint } from "../../types";
import { conjoin, isDarkColor } from "../../utils/utils";
import Button from "../Button";
import styles from "./PaintCard.module.css";

interface Props {
  data: Paint;
  actionTitle?: string;
  actionDisabled?: boolean;
  height?: number;
  onActionClick?: (paint: Paint) => void;
}

const PaintCard = ({
  data,
  actionTitle,
  actionDisabled,
  height,
  onActionClick,
}: Props) => {
  const isDark = useMemo(() => isDarkColor(data.hex), [data.hex]);

  return (
    <div className={styles.container} tabIndex={0}>
      <Link
        to={data.id ? `/paints/${encodeURIComponent(data.id)}` : ""}
        className={styles.content}
      >
        <div
          className={classNames(styles.preview, isDark && styles.dark)}
          style={{ background: data.hex, height }}
        ></div>
        <div className={styles.itemDesc}>
          <div className={styles.itemName} title={`${data.code} ${data.name}`}>
            {data.code} {data.name || "-"}
          </div>
          <div
            className={styles.itemBrand}
            title={`${data.brand} - ${data.line}`}
          >
            {conjoin(" - ", data.brand, data.line)}
          </div>
        </div>
      </Link>
      <Button
        className={styles.actionButton}
        title={actionTitle}
        icon
        onKeyDown={(event) => {
          event.stopPropagation();
        }}
        onClick={(event) => {
          event.stopPropagation();
          onActionClick?.(data);
        }}
        disabled={!data.id}
        variant="translucent"
      >
        {actionDisabled ? (
          <Check size="1.1rem" className="color-success" />
        ) : (
          <Plus size="1.1rem" />
        )}
      </Button>
    </div>
  );
};

export default PaintCard;
