import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
} from "react";

type ThemeType = "light" | "dark";

interface ThemeContextProps {
  theme: ThemeType;
  setTheme: Dispatch<SetStateAction<ThemeType>> | null;
}

export const ThemeContext = React.createContext<ThemeContextProps>({
  theme: "dark",
  setTheme: null,
});

export const ThemeProvider = ({ children }: PropsWithChildren) => {
  const [theme, setTheme] = React.useState<ThemeType>("dark");

  useEffect(() => {
    document.body.dataset.theme = theme;
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => React.useContext(ThemeContext);
