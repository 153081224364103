import classNames from "classnames";
import { X } from "phosphor-react";
import { MouseEventHandler, useMemo } from "react";
import { Paint } from "../../types";
import { isDarkColor } from "../../utils/utils";
import styles from "./PaletteItem.module.css";

interface Props {
  paint: Paint;
  onClick?: MouseEventHandler;
  onColorClick?: MouseEventHandler;
  onDeleteClick?: MouseEventHandler;
}

const PaletteItem = ({
  paint: p,
  onClick,
  onColorClick,
  onDeleteClick,
}: Props) => {
  const isDark = useMemo(() => isDarkColor(p.hex), [p.hex]);

  return (
    <div
      className={classNames(styles.paletteItem, isDark && styles.dark)}
      onClick={onClick}
      style={{ background: p.hex }}
    >
      <div onClick={onColorClick}>
        <strong className={styles.title}>{p.name}</strong>
        <div>
          {p.brand} - {p.line && <> {p.line}</>}
        </div>
      </div>
      <button className={styles.removeButton} onClick={onDeleteClick}>
        <X size={"1.1rem"} />
      </button>
    </div>
  );
};

export default PaletteItem;
