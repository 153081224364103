import PaintCard from "../components/PaintCard";
import { usePalette } from "../palette";
import { Paint } from "../types";

interface Props {
  data: Paint;
}

const ActionPaintCard = ({ data }: Props) => {
  const { addToPalette, paletteContains, deleteFromPalette } = usePalette();

  return (
    <PaintCard
      data={data}
      actionTitle="Add to palette"
      onActionClick={(p) =>
        paletteContains(p.id) ? deleteFromPalette(p.id) : addToPalette(p)
      }
      actionDisabled={paletteContains(data.id)}
    />
  );
};

export default ActionPaintCard;
