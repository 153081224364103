import React, { PropsWithChildren } from "react";

interface NavigationContextProps {
  sidebarOpen: boolean;
  toggleSidebar: () => void;
}

interface NavigationProviderProps extends PropsWithChildren {
  sidebarOpen?: boolean;
}

export const NavigationContext = React.createContext<NavigationContextProps>({
  sidebarOpen: true,
  toggleSidebar: () => {},
});

export const NavigationProvider = ({
  children,
  sidebarOpen: sidebarOpenInitial,
}: NavigationProviderProps) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(
    sidebarOpenInitial ?? true
  );

  return (
    <NavigationContext.Provider
      value={{
        sidebarOpen,
        toggleSidebar: () => setSidebarOpen(!sidebarOpen),
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => React.useContext(NavigationContext);
