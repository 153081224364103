import classNames from "classnames";
import { PropsWithChildren, useEffect } from "react";
import { useNavigation } from "../../navigation";
import SidebarPalette from "../../routes/SidebarPalette";
import { isTablet } from "../../utils/media";
import styles from "./PageLayout.module.css";

interface Props extends PropsWithChildren {}

const PageLayout = ({ children }: Props) => {
  const { sidebarOpen, toggleSidebar } = useNavigation();

  useEffect(() => {
    // Prevent scrolling when sidebar is open on mobile
    if (isTablet() && sidebarOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [sidebarOpen]);

  return (
    <div
      className={classNames(styles.root, sidebarOpen && styles.rootSidebarOpen)}
    >
      {children}
      <div
        className={classNames(
          styles.sidebar,
          sidebarOpen ? styles.open : undefined
        )}
      >
        <SidebarPalette />
      </div>
      <div className={styles.backdrop} onClick={toggleSidebar}></div>
    </div>
  );
};

export const PageLayoutContent = ({ children }: PropsWithChildren) => {
  return <div className={styles.content}>{children}</div>;
};

export const PageLayoutSidepane = ({ children }: PropsWithChildren) => {
  return <div className={styles.sidepane}>{children}</div>;
};

export default PageLayout;
