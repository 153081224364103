import classNames from "classnames";
import { CSSProperties } from "react";
import { createClassNames } from "react-instantsearch-dom";
import { useRefinementList, UseRefinementListProps } from "react-instantsearch";
import "./styles.scss";
import { getContrastColor, nameToColor } from "./utils";

const cx = (...args: string[]) =>
  classNames(createClassNames("ColorRefinementList")(...args));

const RefinedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
  </svg>
);

function CustomRefinementList(props: UseRefinementListProps) {
  const { items, refine } = useRefinementList(props);

  const renderItem = (item: any) => {
    const colorStyles: CSSProperties = {};
    if (item.value) {
      colorStyles.backgroundColor = nameToColor(item.value);
    }
    const colorCn = [cx("color")];
    if (item.value) {
      colorCn.push(`color--${item.value.toLowerCase().substring(1)}`);
    }

    return (
      <button
        type="button"
        key={item.label}
        className={cx("item", item.isRefined ? "itemRefined" : "")}
        role="menuitemcheckbox"
        aria-checked={item.isRefined}
        title={item.value}
        onClick={(event) => {
          event.preventDefault();
          refine(item.value);
        }}
      >
        <div className={colorCn.join(" ")} style={colorStyles}>
          <div
            className={cx("refinedIcon")}
            style={
              {
                "--contrast-color": item.rgb
                  ? getContrastColor(item.rgb)
                  : undefined,
              } as CSSProperties
            }
          >
            <RefinedIcon />
          </div>
        </div>
      </button>
    );
  };

  return (
    <div className={classNames(cx("", `layoutGrid`, `shapeCircle`))}>
      <div className={cx("items")} role="group">
        {items.map(renderItem)}
      </div>
    </div>
  );
}

export default CustomRefinementList;
