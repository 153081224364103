import { Analytics } from "@vercel/analytics/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { PaletteProvider } from "./palette";
import reportWebVitals from "./reportWebVitals";
import ErrorPage from "./routes/error-page";
import Home from "./routes/Home";
import PaintDetails from "./routes/PaintDetails";
import Root from "./routes/Root";
import { ThemeProvider, useTheme } from "./theme";
import { sendToVercelAnalytics } from "./vitals";

import "react-toastify/dist/ReactToastify.css";
import { NavigationProvider } from "./navigation";
import "./styles/algolia/theme.scss";
import "./styles/index.css";
import { isTablet } from "./utils/media";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "paints/:paintId",
        element: <PaintDetails />,
      },
    ],
  },
]);

const ThemedToastContainer = () => {
  const { theme } = useTheme();
  return <ToastContainer theme={theme} position="top-center" />;
};

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <NavigationProvider sidebarOpen={!isTablet()}>
        <PaletteProvider>
          <ThemedToastContainer />
          <RouterProvider router={router} />
        </PaletteProvider>
      </NavigationProvider>
    </ThemeProvider>
    <Analytics />
  </React.StrictMode>
);

reportWebVitals(sendToVercelAnalytics);
